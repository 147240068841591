import { AxiosInstance } from 'axios';

class ApiKeys {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  get() {
    return this.instance.get(`/api-keys`);
  }

  create() {
    return this.instance.post('/api-keys', { isActive: true });
  }

  update(id: string, isActive: boolean) {
    return this.instance.put(`/api-keys/${id}`, { isActive });
  }

  delete(id: string) {
    return this.instance.delete(`/api-keys/${id}`);
  }

}

const _ApiKeys = (instance: AxiosInstance) => new ApiKeys(instance);

export default _ApiKeys;
