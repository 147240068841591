import { AxiosInstance } from 'axios';

class ApiPermissions {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  updateApiPermission(userId: string, enableApi: boolean) {
    return this.instance.put(`/admin/api-permissions`, { userId, isEnabled: enableApi});
  }

}

const _ApiPermissions = (instance: AxiosInstance) => new ApiPermissions(instance);

export default _ApiPermissions;
