import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import api from '../../index';
import { RestResult, RestResultWithData } from './types';

export class SafeRequestError extends Error {
  constructor(public statusCode: number, public error: any) {
    super(`${statusCode} ${error}`);

    Object.setPrototypeOf(this, SafeRequestError.prototype);
  }

  getErrorMessage = (): string => {
    return this.error?.error || this.error?.message || '';
  };
}

/**
 * @deprecated safe request should not be used anymore because doens't throw any errors
 */
export const safeRequest = async <T = any>(
  config: Partial<AxiosRequestConfig>,
): Promise<RestResult<AxiosResponse<T>>> => {
  try {
    const response = await api.instance.request<T>({ ...api.instance.defaults, ...config });
    return {
      statusCode: response.status,
      data: response,
    };
  } catch (e) {
    const error = e as AxiosError;
    if (error.isAxiosError) {
      return {
        statusCode: error.response?.status || 0,
        error,
      };
    }
    return {
      statusCode: 0,
      error: e,
    };
  }
};

export const safeRequestThrowable = async <T = any>(
  config: Partial<AxiosRequestConfig>,
): Promise<RestResultWithData<AxiosResponse<T>>> => {
  try {
    const response = await api.instance.request<T>({
      ...api.instance.defaults,
      ...config,
    });
    return {
      statusCode: response.status,
      data: response,
    };
  } catch (e: any) {
    const error = e as AxiosError;
    if (error.isAxiosError) {
      if (error.response && error.response?.data instanceof Blob) {
        const text = await error.response.data.text();
        throw new SafeRequestError(error.response?.status || 0, JSON.parse(text)?.error);
      } else {
        const parsedError = error.response?.data;
        parsedError.error = parsedError.error;
        throw new SafeRequestError(error.response?.status || 0, parsedError);
      }
    }

    throw new SafeRequestError(0, e);
  }
};
