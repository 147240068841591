import { AxiosInstance } from 'axios';
import auth from './auth';
import users from './users';
import requests from './requests';
import requestsSada from './requestsSada';
import vehicles from './vehicles';
import vehiclesSada from './vehiclesSada';
import clients from './clients';
import apiPermissions from './apiPermissions';
import apiKeys from './apiKeys';
import apiWebhook from './apiWebhook';
import services from './services';
import teams from './teams';
import branchs from './branchs';
import logs from './logs';
import config, { IConfigProps } from './config';

export class Api {
  instance: AxiosInstance;

  constructor(params: IConfigProps) {
    this.instance = config(params).instance();
  }

  auth() {
    return auth(this.instance);
  }

  users() {
    return users(this.instance);
  }

  clients() {
    return clients(this.instance);
  }

  apiPermissions() {
    return apiPermissions(this.instance);
  }

  apiKeys() {
    return apiKeys(this.instance);
  }

  apiWebhook() {
    return apiWebhook(this.instance);
  }

  requests() {
    return requests(this.instance);
  }

  requestsSada() {
    return requestsSada(this.instance);
  }

  vehicles() {
    return vehicles(this.instance);
  }

  vehiclesSada() {
    return vehiclesSada(this.instance);
  }

  services() {
    return services(this.instance);
  }

  teams() {
    return teams(this.instance);
  }

  branchs() {
    return branchs(this.instance);
  }

  logs() {
    return logs(this.instance);
  }
}

const _Api = (params: IConfigProps) => new Api(params);

export default _Api;
