import TypeUser from "./TypeUser";

enum RoutesEnum {
  // APP
  INDEX_ROUTE = '/',
  ADMIN_ROUTE = '/admin',
  // User Authententication
  LOGIN_ROUTE = '/login',
  DASHBOARD_ROUTE = '/dashboard',
  ADMIN_DASHBOARD_ROUTE = '/admin/dashboard',
  LAST_MILE_TRACK_ROUTE = '/last-mile-track',
  ADMIN_LAST_MILE_TRACK_ROUTE = '/admin/last-mile-track',
  ADMIN_MONITORING_VEHICLES_ROUTE = '/admin/monitoring-vehicles',
  USERS_ROUTE = '/users',
  REGISTER_ROUTE = '/register',
  RECOVER_PASSWORD_ROUTE = '/recover-password',
  NEW_PASSWORD_ROUTE = '/reset-password/:token',
  REQUEST_PAGE_ROUTE = '/requests',
  VIEW_PROFILE_ROUTE = '/profile',
  FAQ_ROUTE = '/faq',
  FAQ_SECTION_ROUTE = '/faq/:slug',
  REQUEST_PAGE_ADMIN_ROUTE = '/admin/requests',
  STOCK_PAGE_ROUTE = '/stocks',
  API_INTEGRATION_PAGE_ROUTE = '/api-integration',
  STOCK_PAGE_ADMIN_ROUTE = '/admin/stocks',
  OPERATIONAL_METRICS_PAGE_ADMIN_ROUTE = '/admin/operational-metrics',
  OPERATIONAL_METRICS_PAGE_ROUTE = '/operational-metrics',
  DISAPPROVE_VEHICLES_PAGE_ADMIN_ROUTE = '/admin/requests/disapprove',
  MANAGE_REVIEWS_ADMIN_ROUTE = '/admin/platform-rating',
  VIEW_CLIENT_ROUTE = '/admin/clients',
  EDIT_CLIENT_ROUTE = '/admin/clients/edit-client/:id',
  EDIT_CLIENT_ROUTE_REDIRECT = '/admin/clients/edit-client',
  ADD_USER_ROUTE = '/admin/user/add-user',
  EDIT_USER_ROUTE = '/admin/user/edit-user/:id',
  EDIT_USER_ROUTE_REDIRECT = '/admin/user/edit-user',
  // Routes Team
  VIEW_TEAM_ROUTE = '/admin/team',
  SERVICE_ROUTE = '/admin/service',
  SERVICE_EDIT_ROUTE = '/admin/service/:service',
  SERVICE_CLIENT_ROUTE = '/services',
}

export const RoutesPermission = {
  [TypeUser.ADMIN]: [
    RoutesEnum.ADMIN_DASHBOARD_ROUTE,
    RoutesEnum.ADMIN_LAST_MILE_TRACK_ROUTE,
    RoutesEnum.REQUEST_PAGE_ADMIN_ROUTE,
    RoutesEnum.SERVICE_ROUTE,
    RoutesEnum.DISAPPROVE_VEHICLES_PAGE_ADMIN_ROUTE,
    RoutesEnum.SERVICE_EDIT_ROUTE,
    RoutesEnum.STOCK_PAGE_ADMIN_ROUTE,
    RoutesEnum.OPERATIONAL_METRICS_PAGE_ADMIN_ROUTE,
    RoutesEnum.VIEW_CLIENT_ROUTE,
    RoutesEnum.EDIT_CLIENT_ROUTE,
    RoutesEnum.EDIT_CLIENT_ROUTE_REDIRECT,
    RoutesEnum.ADD_USER_ROUTE,
    RoutesEnum.EDIT_USER_ROUTE,
    RoutesEnum.EDIT_USER_ROUTE_REDIRECT,
    RoutesEnum.VIEW_TEAM_ROUTE,
    RoutesEnum.MANAGE_REVIEWS_ADMIN_ROUTE,
    RoutesEnum.ADMIN_MONITORING_VEHICLES_ROUTE,
  ],
  [TypeUser.CONSULT]: [
    RoutesEnum.ADMIN_DASHBOARD_ROUTE,
    RoutesEnum.ADMIN_LAST_MILE_TRACK_ROUTE,
    RoutesEnum.OPERATIONAL_METRICS_PAGE_ADMIN_ROUTE,
    RoutesEnum.STOCK_PAGE_ADMIN_ROUTE
  ],
  [TypeUser.OPERATIONAL]: [
    RoutesEnum.ADMIN_DASHBOARD_ROUTE,
    RoutesEnum.REQUEST_PAGE_ADMIN_ROUTE,
    RoutesEnum.SERVICE_ROUTE,
    RoutesEnum.SERVICE_EDIT_ROUTE,
    RoutesEnum.DISAPPROVE_VEHICLES_PAGE_ADMIN_ROUTE,
    RoutesEnum.STOCK_PAGE_ADMIN_ROUTE,
    RoutesEnum.OPERATIONAL_METRICS_PAGE_ADMIN_ROUTE,
    RoutesEnum.ADMIN_LAST_MILE_TRACK_ROUTE
  ]
}

export const routes = {
  v2: {
    requests: {
      list: '/requests',
      edit: '/v2/requests/:id',
      delete: '/v2/requests/delete/:id',
      new: '/v2/requests/new',
      view: '/vehicle/:id',
      viewDispatched: '/vehicle-dispatched/:id',
    },
  },
};

export default RoutesEnum;
