import { ICreateUpdateApiWebhookUser } from 'src/context/hooks/useApiWebhook';
import { AxiosInstance } from 'axios';
import TypeApiWebhookAuthentication from 'src/enum/TypeApiWebhookAuthentication';
class ApiWebhook {
  instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  get() {
    return this.instance.get(`/api-webhook`);
  }

  createOrUpdate(data: ICreateUpdateApiWebhookUser) {
    return this.instance.post('/api-webhook',
      {
        host: data.host,
        typeAuthentication: data.typeAuthentication,
        token: data.typeAuthentication === TypeApiWebhookAuthentication.TOKEN ? data.token : null,
        userAuthBasic: data.typeAuthentication === TypeApiWebhookAuthentication.BASIC ? data.userAuthBasic : null,
        passwordAuthBasic: data.typeAuthentication === TypeApiWebhookAuthentication.BASIC ? data.passwordAuthBasic : null
      });
  }

  validateWebhook(data: ICreateUpdateApiWebhookUser) {
    return this.instance.post('/api-webhook/validate',
      {
        host: data.host,
        typeAuthentication: data.typeAuthentication,
        token: data.typeAuthentication === TypeApiWebhookAuthentication.TOKEN ? data.token : null,
        userAuthBasic: data.typeAuthentication === TypeApiWebhookAuthentication.BASIC ? data.userAuthBasic : null,
        passwordAuthBasic: data.typeAuthentication === TypeApiWebhookAuthentication.BASIC ? data.passwordAuthBasic : null
      });
  }
}

const _ApiWebhook = (instance: AxiosInstance) => new ApiWebhook(instance);

export default _ApiWebhook;
